<template>
  <div class="login-form">
    <!-- <div class="d-flex justify-content-between align-items-center">
      <h6 class="my-4">Login With</h6>
      <button class="m-0">
        <img src="~@/assets/images/icons/flat-color-icons_google.png" alt="" />
        Google
      </button>
    </div>
    <div class="d-flex w-100 align-items-center justify-content-between mt-3">
      <p class="mb-0">Or</p>
      <div class="line-horizon"></div>
    </div> -->
    <div class="form-section my-4">
      <b-form @submit.prevent="onLogin">
        <div class="form-group">
          <label for="email">User Email</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fas fa-envelope"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              type="email"
              required
              id="email"
              v-model.trim="login.email"
              placeholder="Enter Your Email"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="password">User Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="fad fa-key"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              type="password"
              required
              id="password"
              v-model.trim="login.password"
              placeholder="Enter Your Password"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group" v-if="showauth">
          <label for="g2a">User 2 Factor Authentication</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text>
                <i class="far fa-shield-alt"></i>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              required
              id="g2a"
              v-model.trim="login.authCode"
              placeholder="Enter Your Code"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <div class="forgot">
            <i class="fad fa-lock-alt"></i>
            <span>
              <router-link :to="{ name: 'Forgot' }">Forgot your password ?</router-link>
            </span>
          </div>
        </div>
        <div class="form-group">
          <b-button
            v-if="Maintainers === 0"
            variant="none"
            type="submit"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            Login
            <i class="fas fa-sign-in-alt ml-1"></i>
          </b-button>
          <b-button
            v-else
            variant="none"
            type="button"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            The System Is Under Maintenance
          </b-button>
        </div>
      </b-form>
    </div>
    <div class="line-horizon w-100 my-5"></div>
    <div class="other-section">
      <div class="text-center redirect-section">
        <h5 class="">Don't have an account ?</h5>
        <router-link :to="{ name: 'Register' }">Free Register ?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      login: {
        email: null,
        password: null,
        authCode: null,
        token: null,
      },
      showauth: false,
    };
  },
  computed: {
    ...mapGetters({
      Maintainers: 'core/Maintainers',
    }),
  },
  created() {
    if (this.$route.query.s && this.$route.query.m) {
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '1') {
        this.$toastr.s(this.$route.query.m);
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '0') {
        this.$toastr.w(this.$route.query.m);
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '-1') {
        this.$toastr.e(this.$route.query.m);
      }
    }
    this.unsubscribe = this.$store.subscribe((mutation) => {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'auth/REQUIRE_AUTH':
          this.showauth = true;
          this.outLoad();
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    async onLogin() {
      if (this.Maintainers === 1) return;
      const token = await this.genarateCaptChaV3('register');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.login.token = token;
      this.$store.dispatch('auth/req_postLogin', this.login);
    },
  },
};
</script>

<style lang="scss" scoped>
a,
h5 {
  font-weight: 500;
}
.line-horizon {
  width: 80%;
  height: 1px;
  background: #9494b0;
}
button {
  max-width: 210px;
  width: 100%;
  height: 46px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  color: #76768e;
  img {
    object-fit: scale-down;
    margin-right: 10px;
  }
}
.login-form {
  min-width: 340px;
  max-width: calc(100% - 25px);
  span {
    &.effect {
      background: #ff5c00;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }
    &.eggsbook {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #5d5d5d;
    }
  }

  .form-section {
    label {
      font-weight: 600;
      color: #6c757d;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-group-text {
      background: transparent;
      padding: 0;
      border: 1px solid #e9ecef;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      border: 1px solid #e9ecef;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
    .form-group {
      .forgot {
        display: flex;
        justify-content: flex-end;

        margin-top: 20px;
        i,
        svg {
          margin-right: 5px;
          color: #98a6ad;
          font-size: clamp(14px, 1.5vw, 16px);
        }
        span a {
          color: #98a6ad;
          font-weight: 500;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
  button {
    margin-top: 20px;

    &.btn-auth {
      font-family: 'Epilogue';
      border: 1px solid #ccc;
      box-shadow: 0 2px 6px 0 #ccc;
      letter-spacing: 0.05rem;
      position: relative;
      background: #ff5c00;
      color: #fff !important;
      max-width: 100%;
      width: 100%;
      height: 45px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      z-index: 1;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .other-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h5 {
      font-weight: 500 !important;
    }
    h6 {
      color: #5d5b6f;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }

    .redirect-section {
      font-weight: 600;
      h5 {
        font-size: clamp(14px, 1.5vw, 16px);
        font-weight: 600;
        color: #888888;
      }
      a {
        margin: 20px auto;
        border-radius: 5px;
        border: 0;
        background: #ff5c00;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        text-decoration: none;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
